import './computers.css';
import 'tw-elements';
import './room_modal';
import RoomComponent from './room_component';
import React from 'react';


function RoomMap(props) {

    let computers = props.computers;
    let pings = props.pings;
    let hardware = props.hardware;

   // console.log("Computers:", computers);
   // console.log("Pings",pings);
   //  console.log("Hardware:",hardware);

    let setChosenComputer = props.setChosenComputer;

    const listItems = computers.map((computer, index) => 

       <RoomComponent 
       key={computer.computer_name} 
       index={index} computer={computer} 
       pings={pings.filter(ping => ping.computer_name === computer.computer_name)} 
       hardware={hardware.filter(hardwareItem => hardwareItem.computer_name === computer.computer_name)}
       chosenFunction={setChosenComputer}
       ></RoomComponent>
         
    );

    console.log(listItems);
    return (
        <React.Fragment>{listItems}</React.Fragment>
          
    )
}

export default RoomMap