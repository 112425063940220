import { useState, useEffect } from 'react';
import './computers.css';
import 'tw-elements';
import dayjs from 'dayjs';

function RoomComponent(props) {

  let [status, setStatus] = useState('Online');
  let [lastSeen, setLastSeen] = useState();
  let [lastPingTime, setLastPingTime] = useState();
  let [backgroundClass, setBackgroundClass] = useState('bg-blue-600');
  let [hardwareInfo, setHardwareInfo] = useState();

  let pings = props.pings;
  let computer = props.computer;

  let setChosenComputer=props.chosenFunction;

  useEffect(() => {

    if (pings == null || pings.length == 0) setStatus('Offline');

    if (pings.length > 0) {

      pings.sort((a, b) => {
        if (a.time > b.time) return -1;
        else if (a.time < b.time) return 1;
        return 0;
      });

      setLastPingTime(pings[0].time);
      setLastSeen(Math.floor((dayjs().diff(lastPingTime)) / (1000 * 60)));

      if (lastSeen < 10) {
        setStatus('On');
        setBackgroundClass('bg-green-600');
      }
      else {
        setStatus('Sleep/Off');
        setBackgroundClass('bg-blue-600');
      }
    }

    if (props.hardware.length > 0) {
      let hardwareString = props.hardware[0].hardware.replaceAll("\\", "");

      setHardwareInfo(hardwareString);
    }
  });



  let hardInfoHTML = null;

  if (hardwareInfo) {

    let hardwareObject = JSON.parse(hardwareInfo);

    const memoryInfo = hardwareObject.memoryInfo.map((element,index) =>
      <span key={index}>{element.size / (1024 * 1024 * 1024)} GB </span>
    );

    const diskInfo = hardwareObject.diskInfo.map((element,index) =>

      <span key={element.index}>{element.name} {Math.floor(element.availableSpace / (1024 * 1024 * 1024))}/{Math.floor(element.totalSize / (1024 * 1024 * 1024))} GB </span>
    )

    hardInfoHTML = <div>
      <p>Hardware:</p>
      <p>OS:{hardwareObject.oSVersion}</p>
      <p>Memory:{memoryInfo}</p>
      <p>Disks:{diskInfo}</p>
    </div>
  }

  return (
    
    <>
    <a id={computer.computer_name} data-bs-toggle="modal"
      data-bs-target="#exampleModal"
      onClick={() => setChosenComputer(computer.computer_name.trim())} 
      href="#" 
      className={`grid-item  block p-6 bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700`}>
<h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
{computer.computer_name}
  </h5>
<p className="font-normal text-gray-700 dark:text-gray-400">
<p>Status: {status}</p>
      <p>Last ping: {lastSeen < 60 ? `${lastSeen} mins.` : `${(Math.floor(lastSeen / 60))} hrs ${(Math.floor(lastSeen % 60))} mins`} </p>
      {hardInfoHTML}
  </p>
</a>


   
    </>
  )
}

export default RoomComponent


{/* <div id={computer.computer_name}
data-bs-toggle="modal"
data-bs-target="#exampleModal"
onClick={() => setChosenComputer(computer.computer_name.trim())}
className={`grid-item grid-column-${computer.x_pos} grid-row-${8 - computer.y_pos} font-sans
${backgroundClass}
px-6 py-2.5 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-700 hover:shadow-lg
focus:bg-green-700 focus:shadow-lg focus:outline-none focus:ring-0
active:bg-green-800 active:shadow-lg
`}>
<p>{computer.computer_name}</p>
<p>Status: {status}</p>
<p>Last ping: {lastSeen < 60 ? `${lastSeen} mins.` : `${(Math.floor(lastSeen / 60))} hrs ${(Math.floor(lastSeen % 60))} mins`} </p>
{hardInfoHTML}
</div> */}


{/*<a id={computer.computer_name} data-bs-toggle="modal"
      data-bs-target="#exampleModal"
      onClick={() => setChosenComputer(computer.computer_name.trim())} 
      href="#" 
      className={`grid-item grid-column-${computer.x_pos} grid-row-${8 - computer.y_pos} block p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700`}>
<h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
{computer.computer_name}
  </h5>
<p className="font-normal text-gray-700 dark:text-gray-400">
<p>Status: {status}</p>
      <p>Last ping: {lastSeen < 60 ? `${lastSeen} mins.` : `${(Math.floor(lastSeen / 60))} hrs ${(Math.floor(lastSeen % 60))} mins`} </p>
      {hardInfoHTML}
  </p>
</a>
*/}
