
function ErrorPage(props) {

    return (
        <div className="flex h-screen">
            <section className="m-auto" >
                <img className="mx-auto" src="/funkypineapple.jpg" alt="Sad Pineapple"></img>
                <h2 class="text-2xl text-center">{props.message}</h2>
            </section>
        </div>
    )
}

export default ErrorPage;
