import { userManager } from './userservice';
import { Redirect } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';


function SignedInChecker() {

    const navigate = useNavigate();
    let [text, setText] = useState('Not logged in');


    

    function LogOut(){

        console.log("Logging out user");

         userManager
      .signoutRedirect()
      .then(res => {
       
          console.log('Redirection to signout triggered.', res);
        
      });

      /*  userManager.querySessionStatus()
        .then((result) => console.log(result))
        .catch((err) => console.log(err));
    
        userManager.removeUser()
        .then((result)=>{
            console.log(result);
        }).catch((err) => console.log(err));

        userManager.signoutCallback()
        .then((result) => {
            console.log(result);

    }); */
}

    useEffect(() => {

        userManager.getUser()
            .then((result) => {
                console.log("Reult of get user", result);
                if (result == null || result == undefined) 
                {
                    navigate('/login');
                }
                else 
                {
                    setText(`Logged in as: ${result.profile.preferred_username}`);
                }
            });
    });
   // <p>{text}</p>  
  // <button onClick={LogOut}>Log Out</button>
  return (
        
    <span>  
        {text}<span> </span>
        <button onClick={LogOut}>(Log Out)</button>
    </span>
    );

}





export default SignedInChecker;