function Select(props){

  const { sets } = props;
  const { reportChangeFunction} = props;

  function onChangeSet(e) {
    console.log(e.target.value);
    reportChangeFunction(e.target.value);
  }

    return (

        sets && sets.length > 0 && (
            <div>
              <select onChange={onChangeSet}>
              <option disabled selected value> -- select a set -- </option>
                {sets.map((set, index) => {
                  return <option key={set}>{set}</option>;
                })}
              </select>
            </div>
          )
    )

}

export default Select;