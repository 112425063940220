import { useState, useEffect } from 'react';
import './computers.css';
import 'tw-elements';
import './room_modal';
import RoomModal from './room_modal';
import RoomMap from './RoomMap';
import Loader from './loader';
import ErrorPage from './ErrorPage';

function Room(props) {

    let [chosenComputer, setChosenComputer] = useState();
    let [computers, setComputers] = useState([]);
    let [err, setErr] = useState();
    let [hardware, setHardware] = useState([]);
    let [loading,setLoading] = useState(false);
    let [pings, setPings] = useState([]);


    async function fetchComputers(){
    
            return fetch(`https://teed.se:3006/telemetry/computers/room/${props.room}`, {
              headers: {'teed_telemetry_api_key': 'e5b5c164-5e04-4532-a76d-6bc4219bcfd0'}
            })
              .then(response => response.json())
              .then(data => {
                setComputers(data);
                setLoading(false);
              }).
              catch((err) => {
                console.log(err);
                setErr(err);
                setLoading(false);
              });
            
    }

    async function fetchPings(){
      
            return fetch(`https://teed.se:3006/telemetry/pings/room/${props.room}`, {
                headers: {
                    'teed_telemetry_api_key': 'e5b5c164-5e04-4532-a76d-6bc4219bcfd0'
                }
            })
                .then(response => response.json())
                .then(data => {
                    console.log(data);
                    setPings(data);
                });
    
}

async function fetchHardware(){
    
        fetch(`https://teed.se:3006/telemetry/hardware/room/${props.room}`, {
            headers: {
                'teed_telemetry_api_key': 'e5b5c164-5e04-4532-a76d-6bc4219bcfd0'
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log(data);
                setHardware(data);
            });


}


  
    useEffect(() => {

        if(props.room!='')
        {
            fetchComputers()
            .then(()=>{fetchPings();})
            .then(()=>{fetchHardware();});
        }
               
    }, []);

    if(props.room==''){return (<p>No set chosen</p>)}

    if (err) {
        return (<ErrorPage message="Ow..Something broke."></ErrorPage>);
      }
    
      if (loading) return (<Loader></Loader>);

    if (!pings) return Loader;
    if (pings.length == 0) return Loader;

    /*<div className="grid-container grid-cols-8">*/

    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4  2xl:grid-cols-8 gap-4">
            <RoomModal chosenComputer={chosenComputer}></RoomModal>
            <RoomMap key={props.room} computers={computers} pings={pings} hardware={hardware} setChosenComputer={setChosenComputer}></RoomMap>
        </div>
    )
}

export default Room