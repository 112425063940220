import { userManager } from './userservice';


function logMeIn() {

    userManager.signinRedirect()
        .then((result) => console.log(result))
        .catch((err) => console.log(err));

}



function Login() {

    return (
        <div className="flex h-screen">
            <section className="m-auto" >
                <img className="mx-auto" src="/pineapple.svg" alt="pineapple"></img>
                <div className="flex justify-center"><h2>Pineapple</h2></div>
                <div className="flex items-center justify-center">
                    <button onClick={logMeIn} className="bg-blue hover:bg-blue-dark text-black font-bold py-2 px-4 rounded" type="button">
                        Sign In
                    </button>
                </div>
            </section>
        </div>
    )
}

export default Login;

{/* <a className="inline-block align-baseline font-bold text-sm text-blue hover:text-blue-darker" href="#">
Forgot Password?
</a> */}