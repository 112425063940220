import { userManager } from "./userservice";
import { useSearchParams, useNavigate} from 'react-router-dom';
import { useState, useEffect } from 'react';


function SigninOidc() {
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    let id_token=searchParams.get('id_token');



    useEffect(() => {

        userManager.signinRedirectCallback(id_token)
    .then((result)=>{
        console.log('Login Result: ', result);
        if(result!=null) navigate('/');
        else{
             navigate('/login');
        }
    });
    
    });


    
    

   


}

export default SigninOidc;

