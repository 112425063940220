import Room from './Room';
import './App.css';
import { useState, useEffect } from 'react';
import Nav from './nav';
import Footer from './footer';
import Select from './Select';

function App() {

  let [chosenSet, setChosenSet] = useState('');
 
  return (
    <div className="App">
      <Nav></Nav>
      <div className="md:container md:mx-auto">
        <h2>Chosen Set: {chosenSet}</h2>
        <Select sets={['A154DA', 'C104DA']} reportChangeFunction={setChosenSet}></Select>
        <Room key={chosenSet} room={chosenSet}></Room>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default App;
