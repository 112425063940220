import {UserManager} from 'oidc-client';
const config = {
    // the URL of our identity server
    authority:  "https://auth.teed.se", 
    // this ID maps to the client ID in the identity client configuration
    client_id: "teedpineapple", 
    // URL to redirect to after login
    redirect_uri: "https://pineapple.teed.se/signin-oidc", 
    response_type: "id_token",
    // the scopes or resources we would like access to
    scope: "openid profile", 
    // URL to redirect to after logout
    post_logout_redirect_uri: "https://pineapple.teed.se/login", 
  };

 
  // initialise!
  export const userManager = new UserManager(config);